import '../../static/css/reset.css'
import '../../static/css/wrapper.css'
import '../../static/css/header.css'
import '../../static/css/breadcrumb.css'
import '../../static/css/contact.css'
import '../../static/css/footer.css'
import React from 'react'
import { Header } from '../components/Header'
import { Breadcrumb } from '../components/Breadcrumb'
import { Footer } from '../components/Footer'
import Seo from '../components/Seo'
import { url } from '../config/domain'

export default function Thanks({ location }: any): JSX.Element {
  const locatePath = location.pathname.split('/')

  return (
    <div>
      <div className="wrapper">
        <Seo
          title={'お問い合わせ - 完了'}
          description={
            'KURORO BLOGを運営するためのお問い合わせ - 完了詳細ページです。KURORO BLOGは、「モノづくりから始まるエンジニア」をコンセプトに、プログラミングに関心を持ってもらうための情報共有サイトです。'
          }
          url={url + 'thanks/'}
          noIndex={true}
          contentType="article"
        />
        <Header location={locatePath} />

        <h1 className="contact__finish--h1">お問い合わせ - 完了</h1>

        <div className="contact__finish">
          <h2 className="contact__finish--h2">ありがとうございました。</h2>

          <p className="contact__finish--text">
            お送りいただきました内容を確認の上、
            <br />
            KUROROよりご連絡させていただきます。
          </p>
          <div className="contact__finish--controlBtn">
            <a className="contact__finish--btn" href="/">
              トップへ
            </a>
          </div>
        </div>

        <Breadcrumb
          breadcrumb={[
            {
              link: '/',
              text: 'KURORO BLOG',
            },
            {
              link: '/thanks/',
              text: 'お問い合わせ完了',
            },
          ]}
        />
        <Footer />
      </div>
    </div>
  )
}
